// assets
import { IconCurrencyRupee,IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';
import {  GiReceiveMoney,GiTakeMyMoney,GiArchiveRegister,GiPieChart,GiCalculator,GiCoins,GiPaperClip ,GiChart} from "react-icons/gi";
import { MdFamilyRestroom,MdAddCard,MdAutoGraph,MdPieChart,MdBarChart,MdStackedLineChart,MdOutlinePieChartOutline,MdInsertChart,MdOutlineAlignVerticalBottom,MdOutlineInsertChartOutlined } from "react-icons/md";
import { FaRegChartBar,FaChartLine,FaChartPie } from "react-icons/fa";
import { PiCertificateLight ,PiChartLineBold,PiHeadsetBold,PiNotepadBold,PiChartBarBold,PiChartScatterBold,PiChartPieSliceBold} from "react-icons/pi";
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconCurrencyRupee,
    GiReceiveMoney,
    GiTakeMyMoney,
    GiArchiveRegister,
    GiCalculator,
    GiCoins,
    MdFamilyRestroom,
    PiCertificateLight,
    MdAddCard,
    GiPaperClip,
    GiChart,
    PiChartLineBold,
    PiHeadsetBold,
    PiNotepadBold,
    PiChartBarBold,
    PiChartPieSliceBold,
    FaRegChartBar,FaChartLine,
    MdAutoGraph,
    MdBarChart,
    MdStackedLineChart,
    MdInsertChart,GiPieChart,FaChartPie,PiChartScatterBold,
    MdOutlineAlignVerticalBottom,MdOutlineInsertChartOutlined,MdOutlinePieChartOutline,MdPieChart
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Components',
    type: 'group',
    children: [
         {
            id: 'Services',
            title: 'Services',
            type: 'collapse',
            icon: icons.PiHeadsetBold,

            children: [
        
        {
            id: 'util-ITR',
            title: 'ITR Filing',
            type: 'item',
            url: '/dashboard/utils/util-ITR',
            icon: icons.GiReceiveMoney,
            breadcrumbs: false
        },
        {
            id: 'util-GSTF',
            title: 'GST Filing',
            type: 'item',
            url: 'dashboard/utils/util-GST',
            icon: icons.GiTakeMyMoney,
            breadcrumbs: false
        },
        {
            id: 'util-GSTR',
            title: 'GST Registration',
            type: 'item',
            url: '/dashboard/utils/util-GSTReg',
            icon: icons.GiArchiveRegister,
            breadcrumbs: false
        },
        {
            id: 'util-accounting',
            title: 'Accounting',
            type: 'item',
            url: '/dashboard/utils/util-Accounting',
            icon: icons.GiCalculator,
            breadcrumbs: false
        },
        {
            id: 'util-tds',
            title: 'TDS',
            type: 'item',
            url: '/dashboard/utils/util-TDS',
            icon: icons.GiCoins,
            breadcrumbs: false
        },
        {
            id: 'util-epf',
            title: 'EPF',
            type: 'item',
            url: '/dashboard/utils/util-EPF',
            icon: icons.MdFamilyRestroom,
            breadcrumbs: false
        },
        {
            id: 'util-ptrc',
            title: 'PTRC/PTEC',
            type: 'item',
            url: '/dashboard/utils/util-PTRC',
            icon: icons.PiCertificateLight,
            breadcrumbs: false
        },
        {
            id: 'util-other',
            title: 'Other',
            type: 'item',
            url: '/dashboard/utils/util-other',
            icon: icons.MdAddCard,
            breadcrumbs: false
        }
    ]},

        {
            id: 'icons',
            title: 'Status Pages',
            type: 'collapse',
            icon: icons.GiPaperClip,
            children: [
                {
                    id: 'tabler-ITR',
                    title: 'ITR Status',
                    type: 'item',
                    icon: icons.MdAutoGraph,
                    url: 'dashboard/utils/util-ITRStatus',
                    breadcrumbs: false
                },
                {
                    id: 'material-GST',
                    title: 'GST Status',
                    icon: icons.PiChartLineBold,
                    type: 'item',
                    url: '/dashboard/utils/util-GSTStatus',
                    breadcrumbs: false
                },
                {
                    id: 'material-Accounting',
                    title: 'Accouting Status',
                    icon: icons.FaRegChartBar,
                    type: 'item',
                    url: '/dashboard/utils/util-AccountingStatus',
                    breadcrumbs: false
                }
                , {
                    id: 'material-TDS',
                    title: 'TDS Status',
                    icon: icons.MdBarChart,
                    type: 'item',
                    url: '/dashboard/utils/util-TDSStatus',
                    breadcrumbs: false
                },
                {
                    id: 'material-EPF',
                    title: 'EPF Status',
                    icon: icons.MdStackedLineChart,
                    type: 'item',
                    url: '/dashboard/utils/util-EPFStatus',
                    breadcrumbs: false
                },
                {
                    id: 'material-PTRC',
                    title: 'PTEC/PTRC Status',
                    icon: icons.MdOutlineAlignVerticalBottom,
                    type: 'item',
                    url: '/dashboard/utils/util-PTRCStatus',
                    breadcrumbs: false
                },
                {
                    id: 'material-Other',
                    title: 'Other Status',
                    icon: icons.MdOutlineInsertChartOutlined,
                    type: 'item',
                    url: '/dashboard/utils/util-OtherStatus',
                    breadcrumbs: false
                }
             
            ]
        },
        {
            id: 'icons',
            title: 'Report Pages',
            type: 'collapse',
            icon: icons.PiNotepadBold,
            children: [
                {
                    id: 'tabler-ITR',
                    title: 'ITR Report',
                    type: 'item',
                    icon: icons.PiChartBarBold,
                    url: '/dashboard/utils/util-ITR-Report',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-GST',
                    title: 'GST Report',
                    icon: icons.GiPieChart,
                    type: 'item',
                    url: '/dashboard/utils/util-GST-Report',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-Accounting',
                    title: 'Accounting Report',
                    icon: icons.MdOutlinePieChartOutline,
                    type: 'item',
                    url: '/dashboard/utils/util-Accounting-Report',
                    breadcrumbs: false
                }, {
                    id: 'tabler-TDS',
                    title: 'TDS Report',
                    icon: icons.MdPieChart,
                    type: 'item',
                    url: '/dashboard/utils/util-TDS-Report',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-EPFReport',
                    title: 'EPF Report',
                    icon: icons.PiChartPieSliceBold,
                    type: 'item',
                    url: '/dashboard/utils/util-EPF-Report',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-PTRC',
                    title: 'PTEC/PTRC Report',
                    icon: icons.FaChartPie,
                    type: 'item',
                    url: '/dashboard/utils/util-PTRC-Report',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-Other',
                    title: 'Other Report',
                    icon: icons.PiChartScatterBold,
                    type: 'item',
                    url: '/dashboard/utils/util-Other-Report',
                    breadcrumbs: false
                }
                
            ]
        }
    ]
};

export default utilities;
