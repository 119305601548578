import React from 'react';
import { Paper, Container, Box, Grid, TextField, MenuItem, FormControlLabel, Radio, RadioGroup, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { SaveTwoTone } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';

const BankAndOtherDetails = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="lg" sx={{ marginTop: 4 }}>
            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    borderRadius: 3,
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#ffffff'
                }}
            >
                <Grid container spacing={3}>
                    {/* Address Details Section */}
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                textAlign: 'left',
                                color: '#1976d2'
                            }}
                        >
                            Bank Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Bank Name" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Account Name" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Account Number" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Branch" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="IFSC Code" variant="outlined" />
                    </Grid>
                    
                    
                    {/* GST Details Section */}
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                textAlign: 'left',
                                color: '#1976d2'
                            }}
                        >
                            Others Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="PTRC/PTEC Number" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="PTRC/PTEC Password" variant="outlined" />
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="EPFO Number" type="date" variant="outlined" InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="EPFO Login" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth label="Other Registration" variant="outlined" />
                    </Grid>
                    
                </Grid>
                <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBackTwoToneIcon />}
                        onClick={() => {
                            navigate('/view/addngstdetails');
                        }}
                        sx={{ marginRight: 2 }}
                    >
                        Previous
                    </Button>{' '}
                    <Button startIcon={<SaveIcon />} variant="contained" color="primary" sx={{ marginRight: 2 }}onClick={() => {
                            navigate('/dashboard/master/company');
                        }}>
                        Submit
                    </Button>
                    <Button startIcon={<RestartAltIcon />} variant="outlined" color="secondary">
                        Reset
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default BankAndOtherDetails;
