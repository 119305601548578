// assets
import { IconDashboard } from '@tabler/icons';
import { GiOrganigram, GiShakingHands } from 'react-icons/gi';
import { FaUserTie } from 'react-icons/fa';
import { FaUserGear } from "react-icons/fa6";

// constant
const icons = { IconDashboard, GiOrganigram, GiShakingHands, FaUserTie,FaUserGear };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'master',
    title: 'master',
    type: 'group',
    children: [
        {
            id: 'usermaster',
            title: 'Person',
            type: 'item',
            url: '/dashboard/master/persons',
            icon: icons.FaUserTie,
            breadcrumbs: false
        },
        {
            id: 'personmaster',
            title: 'User',
            type: 'item',
            url: '/dashboard/master/users',
            icon: icons.FaUserGear,
            breadcrumbs: false
        },
        {
            id: 'orgmaster',
            title: 'Organisation',
            type: 'item',
            url: '/dashboard/master/companymaster', // /view/personalinfoform -> for creation use this
            icon: icons.GiOrganigram,
            breadcrumbs: false
        },
        {
            id: 'endclient',
            title: 'End Client',
            type: 'item',
            url: '/dashboard/master/EndClient',
            icon: icons.GiShakingHands,
            breadcrumbs: false
        },
        
    ]
};

export default dashboard;
