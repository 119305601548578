import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { element } from 'prop-types';
import BankAndOtherDetails from 'views/master/org/banknotherdetails';
// import ResetPassword from 'views/pages/authentication/authentication3/Resetpassword';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
 const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const EditUser = Loadable(lazy(() => import('views/sample-page/ForgetPassword')));

const OrgPersonalInfo = Loadable(lazy(() => import('views/master/org/personalinfoform')));
const OrgAddngstdetails = Loadable(lazy(() => import('views/master/org/addngstdetails')));
const OrgBankAndOtherDetails = Loadable(lazy(() => import('views/master/org/banknotherdetails')));
// const UserCreation = Loadable(lazy(() => import('views/users/usercreations')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },

        {
            path: '/',
            element: <AuthLogin3 />
        },
       
        {
            path: '/pages/EditUser',
            element: <EditUser />
        },{
            path: '/view/personalinfoform',
            element: <OrgPersonalInfo />
        },{
            path: '/view/addngstdetails',
            element: <OrgAddngstdetails />
        },{
            path: '/view/banknotherdetails',
            element: <OrgBankAndOtherDetails />
        }
  ]
};

export default AuthenticationRoutes;
