import dashboard from './dashboard';
import master from './master';
import team from './team';
// import pages from './pages';
import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

/* const menuItems = {
    items: [dashboard,master, utilities]
}; */


const menuItems = {
    items: [master]
};

export default menuItems;
