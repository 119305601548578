// material-ui
import { useTheme } from '@mui/material/styles';
import logo1 from '../assets/images/LnS Plain Logo_Only Circle.png';

/**
 * if you want to use image instead of <svg> uncomment the following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use an image instead of an svg, uncomment the following, and comment out the <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <>
      <img src={logo1} alt="logo" width="60px" height="60px" /> &nbsp;&nbsp;
      <div className="container" style={{ color: '#0e65f0', fontSize: '20px' }}>
        <b className="title">INFUSION CRM</b>
      </div>
    </>
  );
};

export default Logo;
