import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UtilsITR = Loadable(lazy(() => import('views/utilities/ITR')));
const UtilsPersonalInfo = Loadable(lazy(() => import('views/utilities/infoforms/PersonalInfo')));
const UtilsAddressDetail = Loadable(lazy(() => import('views/utilities/infoforms/AddressDetail')));
const UtilsBankDetail = Loadable(lazy(() => import('views/utilities/infoforms/BankDetail')));
const UtilsOtherDetail = Loadable(lazy(() => import('views/utilities/infoforms/OtherDetail')));
const UtilsUpload = Loadable(lazy(() => import('views/utilities/infoforms/Upload')));
const UtilsGST = Loadable(lazy(() => import('views/utilities/GSTFiling')));
const UtilsGSTDetail = Loadable(lazy(() => import('views/utilities/infoforms/GSTDetail')));
const UtilsGSTReg = Loadable(lazy(() => import('views/utilities/GSTReg')));
const UtilsTDS = Loadable(lazy(() => import('views/utilities/TDS')));
const UtilsEPF = Loadable(lazy(() => import('views/utilities/EPF')));
const UtilsPTRC = Loadable(lazy(() => import('views/utilities/PTRC')));
const UtilsOther = Loadable(lazy(() => import('views/utilities/Other')));
const UtilsAccounting = Loadable(lazy(() => import('views/utilities/Accounting')));
const UtilsITRStatus = Loadable(lazy(() => import('views/utilities/Status/ITRStatus')));
const UtilsGSTStatus = Loadable(lazy(() => import('views/utilities/Status/GSTStatus')));
const MasterComp = Loadable(lazy(() => import('views/master/company')));
const MasterOrg = Loadable(lazy(() => import('views/master/Org')));
const MasterEndClient = Loadable(lazy(() => import('views/master/EndClient')));
const UtilsAccountingStatus = Loadable(lazy(() => import('views/utilities/Status/AccountingStatus')));
const UtilsAddressStatus = Loadable(lazy(() => import('views/utilities/Status/AddressStatus')));
const UtilsEPFStatus = Loadable(lazy(() => import('views/utilities/Status/EPFStatus')));
const UtilsPTRCStatus = Loadable(lazy(() => import('views/utilities/Status/PTRCStatus')));
const UtilsTDSStatus = Loadable(lazy(() => import('views/utilities/Status/TDSStatus')));
const UtilsOrgStatus = Loadable(lazy(() => import('views/utilities/Status/OrgStatus')));
const UtilsEndClientStatus = Loadable(lazy(() => import('views/utilities/Status/EndClientStatus')));
const UtilsCompStatus = Loadable(lazy(() => import('views/utilities/Status/CompStatus')));

const UtilsAccountingReport = Loadable(lazy(() => import('views/utilities/Reports/AccountingReport')));

const UtilsITRReport = Loadable(lazy(() => import('views/utilities/Reports/ITRReport')));
const UtilsGSTReport = Loadable(lazy(() => import('views/utilities/Reports/GSTReport')));
const UtilsTDSReport = Loadable(lazy(() => import('views/utilities/Reports/TDSReport')));
const UtilsEPFReport = Loadable(lazy(() => import('views/utilities/Reports/EPFReport')));
const UtilsPTRCReport = Loadable(lazy(() => import('views/utilities/Reports/PTRCReport')));

const CompanyMaster = Loadable(lazy(() => import('views/master/companymaster')));

// sample page routing
const UserStatus = Loadable(lazy(() => import('views/sample-page/Users')));
const EditUser = Loadable(lazy(() => import('views/sample-page/EditUser')));
const ForgetPassword = Loadable(lazy(() => import('views/sample-page/ForgetPassword')));
const PasswordReset = Loadable(lazy(() => import('views/sample-page/PasswordReset')));
const PasswordResetRequest = Loadable(lazy(() => import('views/sample-page/PasswordResetRequest')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const UserList = Loadable(lazy(() => import('views/users/index')));
const UserCreationsList = Loadable(lazy(() => import('views/users/usercreationlist')));

// ==============================|| MAIN ROUTING ||==============================//

const MainRoutes = {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard/pages/register/register3',
            element: <AuthRegister3 />
        },

        {
            path: '/dashboard',
            element: <DashboardDefault />
        },

        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/utils/util-ITR',
            element: <UtilsITR />
        },
        {
            path: '/dashboard/utils/Util-PersonalInfo',
            element: <UtilsPersonalInfo />
        },
        {
            path: '/dashboard/utils/Util-AddressDetail',
            element: <UtilsAddressDetail />
        },
        {
            path: '/dashboard/utils/Util-BankDetail',
            element: <UtilsBankDetail />
        },
        {
            path: '/dashboard/utils/Util-OtherDetail',
            element: <UtilsOtherDetail />
        },
        {
            path: '/dashboard/utils/Util-Upload',
            element: <UtilsUpload />
        },
        {
            path: '/dashboard/utils/Util-GST',
            element: <UtilsGST />
        },
        {
            path: '/dashboard/utils/Util-GSTDetail',
            element: <UtilsGSTDetail />
        },
        {
            path: '/dashboard/utils/Util-GSTReg',
            element: <UtilsGSTReg />
        },
        {
            path: '/dashboard/utils/Util-TDS',
            element: <UtilsTDS />
        },
        {
            path: '/dashboard/utils/Util-EPF',
            element: <UtilsEPF />
        },
        {
            path: '/dashboard/utils/Util-PTRC',
            element: <UtilsPTRC />
        },
        {
            path: '/dashboard/utils/Util-Other',
            element: <UtilsOther />
        },

        {
            path: '/dashboard/utils/Util-Accounting',
            element: <UtilsAccounting />
        },
        {
            path: '/dashboard/utils/Util-ITRStatus',
            element: <UtilsITRStatus />
        },
        {
            path: '/dashboard/utils/Util-GSTStatus',
            element: <UtilsGSTStatus />
        },
        {
            path: '/dashboard/utils/Util-PTRCStatus',
            element: <UtilsPTRCStatus />
        },
        {
            path: '/dashboard/utils/Util-EPFStatus',
            element: <UtilsEPFStatus />
        },
        {
            path: '/dashboard/utils/Util-TDSStatus',
            element: <UtilsTDSStatus />
        },
        {
            path: '/dashboard/utils/Util-OrgStatus',
            element: <UtilsOrgStatus />
        },
        {
            path: '/dashboard/utils/Util-CompStatus',
            element: <UtilsCompStatus />
        },
        {
            path: '/dashboard/utils/Util-EndClientStatus',
            element: <UtilsEndClientStatus />
        },
        {
            path: '/dashboard/utils/Util-AccountingStatus',
            element: <UtilsAccountingStatus />
        },
        {
            path: '/dashboard/utils/Util-AddressStatus',
            element: <UtilsAddressStatus />
        },
        {
            path: '/dashboard/master/Org',
            element: <MasterOrg />
        },
        {
            path: '/dashboard/master/company',
            element: <MasterComp />
        },
        {
            path: '/dashboard/master/EndClient',
            element: <MasterEndClient />
        },
        {
            path: '/dashboard/utils/Util-ITR-Report',
            element: <UtilsITRReport />
        },
        {
            path: '/dashboard/utils/Util-GST-Report',
            element: <UtilsGSTReport />
        },
        {
            path: '/dashboard/utils/Util-Accounting-Report',
            element: <UtilsAccountingReport />
        },
        {
            path: '/dashboard/utils/Util-EPF-Report',
            element: <UtilsEPFReport />
        },
        {
            path: '/dashboard/utils/Util-PTRC-Report',
            element: <UtilsPTRCReport />
        },
        {
            path: '/dashboard/utils/Util-TDS-Report',
            element: <UtilsTDSReport />
        },
        {
            path: '/dashboard/pages/page-Users',
            element: <UserStatus />
        },
        {
            path: '/dashboard/pages/page-EditUser',
            element: <EditUser />
        },
        {
            path: '/dashboard/pages/page-ForgetPassword',
            element: <ForgetPassword />
        },
        {
            path: '/dashboard/pages/page-PasswordReset:token',
            element: <PasswordReset />
        },
        {
            path: '/dashboard/pages/page-PasswordResetRequest',
            element: <PasswordResetRequest />
        },
        {
            path: '/dashboard/master/companymaster',
            element: <CompanyMaster />
        },
        {
            path: '/dashboard/master/persons',
            element: <UserList />
        },
        {
            path: '/dashboard/master/users',
            element: <UserCreationsList />
        }
    ]
};

export default MainRoutes;
