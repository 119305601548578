// assets
import { IconDashboard } from '@tabler/icons';
import { GiOrganigram,GiShakingHands} from "react-icons/gi";
import { FaUserTie } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";

// constant
const icons = { IconDashboard,GiOrganigram ,GiShakingHands,FaUserTie,FaUserGear};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const team = {
    id: 'team',
    title: 'team',
    type: 'group',
    children: [
       
        {
            id: 'usermaster',
            title: 'List',
            type: 'item',
            url: '/dashboard/master/persons',
            icon: icons.GiShakingHands,
            breadcrumbs: false
        },
        {
            id: 'personmaster',
            title: 'Permision',
            type: 'item',
            url: '/dashboard/master/users',
            icon: icons.FaUserGear,
            breadcrumbs: false
        }
    ]
};

export default team;
